import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { formatPhoneNumberHref } from "../../utils"

import SocialIconsGroup from "./socialIconsGroup"
import Logo from "../../images/amplified-logo.svg"

import "./siteFooter.scss"

const Footer = () => (
  <StaticQuery
    query={graphql`
      query getFooterGlobalsQuery {
        craft {
          globals {
            general {
              ... on Craft_General {
                yearFounded
                email
                addresses: address {
                  ... on Craft_AddressAddress {
                    id
                    street: addressStreet
                    street2: addressStreet2
                    city: addressCity
                    state: addressState
                    zipCode: addressZip
                  }
                }
                phoneNumber
              }
            }
          }
        }
      }
    `}
    render={data => {
      const {
        addresses,
        email,
        phoneNumber,
        yearFounded,
      } = data.craft.globals.general
      return (
        <footer id="footer" className="site-footer">
          <div className="footer__wrapper">
            <div className="footer__content">
              {email ? (
                <h1 className="call-to-action">
                  <a href={`mailto:${email}`} target="_top">
                    Say hello.
                  </a>
                </h1>
              ) : null}

              <SocialIconsGroup />

              <div className="footer__address">
                <Logo className="footer__logo" />
                {addresses.length > 0 &&
                  addresses.map(
                    ({ id, street, street2, city, state, zipCode }) => (
                      <address key={`address-${id}`}>
                        {`${street}${street2 ? `, ${street2}` : ""}`},{" "}
                        <span>{city},</span> {state} {zipCode} |{" "}
                        <a
                          className="tele"
                          href={formatPhoneNumberHref(phoneNumber)}
                        >
                          {phoneNumber}
                        </a>
                      </address>
                    )
                  )}
              </div>
            </div>

            <small className="copyright">
              Amplified &copy; {yearFounded}-
              <span className="current-year">
                {new Date()
                  .getFullYear()
                  .toString()
                  .slice(-2)}
              </span>
            </small>
          </div>
        </footer>
      )
    }}
  />
)

export default Footer
