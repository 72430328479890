/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { CLOUDINARY_URL, SITE_URL } from "../../constants"

function SEO({ description, lang, meta, keywords, title, image, location }) {
  const {
    craft: {
      globals: { seo },
    },
  } = useStaticQuery(
    graphql`
      query getSEOFieldsQuery {
        craft {
          globals {
            seo {
              ... on Craft_Seo {
                metaSiteTitle
                metaKeywords
                ogTitle
                ogImage {
                  filename
                  mimeType
                  height
                  width
                }
                ogDescription
              }
            }
          }
        }
      }
    `
  )

  const imageArr = image || seo.ogImage || null

  const metaDescription = description || seo.ogDescription
  const metaSiteTitle = title || seo.metaSiteTitle
  const ogTitle = seo.ogTitle || metaSiteTitle
  const metaKeywords = keywords || seo.metaKeywords
  const metaOgImage =
    imageArr.length > 0
      ? {
          property: `og:image`,
          content: `${CLOUDINARY_URL}/${imageArr[0].filename}`,
        }
      : {}
  const metaOgImageHeight =
    imageArr.length > 0
      ? { property: `og:image:height`, content: imageArr[0].height }
      : {}
  const metaOgImageWidth =
    imageArr.length > 0
      ? { property: `og:image:width`, content: imageArr[0].width }
      : {}
  const metaOgImageType =
    imageArr.length > 0
      ? { property: `og:image:width`, content: imageArr[0].mimeType }
      : {}
  const metaTwitterImage =
    imageArr.length > 0
      ? {
          name: `twitter:image:src`,
          content: `${CLOUDINARY_URL}/${imageArr[0].filename}`,
        }
      : {}

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaSiteTitle}
      titleTemplate={`%s | Amplified`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: metaKeywords,
        },
        {
          property: `og:title`,
          content: ogTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        metaOgImage,
        metaOgImageWidth,
        metaOgImageType,
        metaOgImageHeight,
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: location.href,
        },
        {
          property: `og:site_name`,
          content: `Amplified`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:site`,
          content: SITE_URL,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        metaTwitterImage,
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  keywords: "",
  meta: [],
  image: null,
  location: {
    href: SITE_URL,
  },
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  keywords: PropTypes.string,
  meta: PropTypes.array,
  title: PropTypes.string,
  image: PropTypes.arrayOf(
    PropTypes.shape({
      filename: PropTypes.string,
    })
  ),
  location: PropTypes.shape({
    href: PropTypes.string,
  }),
}

export default SEO
