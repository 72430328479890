import React from "react"

import Konami from "../layout/konami"
import "./siteDiagnostics.scss"

const Drop = ({ i, left, top }) => (
  <div id={`drop${i}`} className="drop" style={{ left, top }} />
)

class SiteDiagnostics extends React.Component {
  constructor(props) {
    super(props)

    this.konamiDrops = []

    this.state = {
      showRain: false,
    }
  }

  getGeneratedDrops = () => {
    // How much precipitation
    const nbDrop = 250

    // function to generate a random number range.
    const randRange = (minNum, maxNum) => {
      return Math.floor(Math.random() * (maxNum - minNum + 1)) + minNum
    }

    // function to generate drops
    if (this.state.showRain) {
      for (let i = 1; i < nbDrop; i++) {
        const dropLeft = randRange(0, 2560)
        const dropTop = randRange(-1000, 1400)
        this.konamiDrops.push(
          <Drop key={`drop-${i}`} i={i} left={dropLeft} top={dropTop} />
        )
      }
    }
    return this.konamiDrops
  }

  easterEgg = () => {
    this.setState(
      ({ showRain }) => ({ showRain: !showRain }),
      this.getGeneratedDrops
    )
  }

  render() {
    return (
      <Konami id="site-diagnostics" className="rain" action={this.easterEgg}>
        {this.konamiDrops}
      </Konami>
    )
  }
}

export default SiteDiagnostics
