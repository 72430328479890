/**
 * DEPRECATED
 *
 * Decided just to use Moment instead since it's only being used to generate output
 * Leaving this here for future reference
 * @param {string} datestr
 * @param {string} format
 */
export const formatDateTime = (datestr, format = "MMMM YYYY") => {
  const timestamp = new Date(datestr * 1000)
  const year = timestamp.getFullYear()
  const month = timestamp.getMonth()
  const monthName = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ][month]
  const date = timestamp.getDate()
  const hours = timestamp.getHours()
  const minutes = timestamp.getMinutes()
  const seconds = timestamp.getSeconds()

  const nth = d => {
    if (d > 3 && d < 21) return "th"
    switch (d % 10) {
      case 1:
        return "st"
      case 2:
        return "nd"
      case 3:
        return "rd"
      default:
        return "th"
    }
  }

  const dd = d => ("00" + d).substr(-2)

  const formatted = {
    "MMMM YYYY": `${monthName} ${year}`,
    "MMMM Do, YYYY": `${monthName} ${date}${nth(date)}, ${year}`,
    "YYYY-DD-MM": `${year}-${dd(date)}-${dd(month + 1)}`,
    "YYYY-MM-DD": `${year}-${dd(month + 1)}-${dd(date)}`,
    "MM/DD/YYYY": `${dd(month + 1)}/${dd(date)}/${year}`,
    "YYYY-MM-DD hh:mm:ss": `${year}-${dd(month + 1)}-${dd(date)} ${dd(
      hours
    )}:${dd(minutes)}:${dd(seconds)}`,
  }

  return formatted[format]
}

export const getCurrentSectionTitle = path => {
  const pageTitles = {
    homepage: "Home",
    culture: "Our Culture",
    "open-mic": "Open Mic",
    work: "Our Work",
  }

  return path.split("/").length > 0 && path.split("/")[1] !== ""
    ? pageTitles[path.split("/")[1]]
    : pageTitles.homepage
}

export const formatPhoneNumberHref = numberStr =>
  `tel:${numberStr.replace(/([().-])/g, "").trim()}`

// Open-Mic Masonry Functions
export const resizeMasonryItem = item => {
  if (typeof document !== `undefined` && typeof window !== `undefined`) {
    const grid = document.getElementsByClassName("open-mic__posts")[0]
    const rowHeight = parseInt(
      window.getComputedStyle(grid).getPropertyValue("grid-auto-rows")
    )
    const rowGap = parseInt(
      window.getComputedStyle(grid).getPropertyValue("grid-row-gap")
    )
    const rowSpan = Math.ceil(
      (item.querySelector(".post").getBoundingClientRect().height + rowGap) /
        (rowHeight + rowGap)
    )
    item.style.gridRowEnd = "span " + rowSpan
  }
}
export const resizeAllMasonryItems = () => {
  if (typeof document !== `undefined`) {
    const allItems = document.getElementsByClassName("open-mic__post-container")
    for (let x = 0; x < allItems.length; x++) {
      resizeMasonryItem(allItems[x])
    }
  }
}
