import React from "react"
import { graphql, StaticQuery } from "gatsby"

import LinkedInIcon from "../../images/social-linkedin.svg"
import InstagramIcon from "../../images/social-instagram.svg"
import GoogleMapsIcon from "../../images/social-googlemaps.svg"
import "./socialIconsGroup.scss"

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query getSocialLinks {
          craft {
            globals {
              social {
                ... on Craft_Social {
                  linkedinUrl
                  instagramUrl
                  googleMapsUrl
                }
              }
            }
          }
        }
      `}
      render={data => {
        const {
          linkedinUrl,
          instagramUrl,
          googleMapsUrl,
        } = data.craft.globals.social
        return (
          <div className="social-links">
            {linkedinUrl && (
              <a
                href={linkedinUrl}
                className="link--linkedIn"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon />
              </a>
            )}
            {instagramUrl && (
              <a
                href={instagramUrl}
                className="link--instagram"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon />
              </a>
            )}
            {googleMapsUrl && (
              <a
                href={googleMapsUrl}
                className="link--googleMaps"
                target="_blank"
                rel="noopener noreferrer"
              >
                <GoogleMapsIcon />
              </a>
            )}
          </div>
        )
      }}
    />
  )
}
