import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

import SocialIconsGroup from "./socialIconsGroup"

import "./siteMenu.scss"

export default ({ location, isMenuVisible, onTogglingMenu }) => (
  <StaticQuery
    query={graphql`
      query fetchGlobalMenuItemsQuery {
        craft {
          globals {
            menu {
              ... on Craft_Menu {
                mainNavigation {
                  id
                  ... on Craft_NavigationMenuItem {
                    id
                    title
                    menuUrl
                  }
                }
              }
            }
            general {
              ... on Craft_General {
                email
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { general, menu } = data.craft.globals
      const { email } = general
      const { mainNavigation } = menu
      return (
        <nav
          id="menu"
          className={`site-menu ${isMenuVisible ? "menu--visible" : ""}`}
        >
          <div className="site-menu__inner">
            <ul>
              {mainNavigation.length > 0 &&
                mainNavigation.map(({ id, title, menuUrl }) => (
                  <li key={id}>
                    <Link
                      to={menuUrl}
                      activeClassName="active"
                      onClick={onTogglingMenu}
                      getProps={({ isPartiallyCurrent }) => {
                        return (isPartiallyCurrent && menuUrl !== "/") ||
                          (location.pathname === "/" && menuUrl === "/")
                          ? { className: "active" }
                          : null
                      }}
                    >
                      {title}
                    </Link>
                  </li>
                ))}
            </ul>

            <SocialIconsGroup />

            <div className="site-menu__extra">
              <a href={`mailto:${email}`} target="_top" className="btn">
                Say hello
              </a>
            </div>
          </div>
        </nav>
      )
    }}
  />
)
