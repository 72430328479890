import React from "react"
import { Link, withPrefix } from "gatsby"
import { getCurrentSectionTitle } from "../../utils"

import MainNavigation from "./siteMenu"

import Logo from "../../images/a-logo.svg"

import "./siteHeader.scss"

const MenuButton = ({ toggleMenu }) => (
  <button
    className={`header__btn header__menu menu-trigger`}
    id="menu-trigger"
    title="Show/Hide menu..."
    type="button"
    onClick={toggleMenu}
  >
    <span className="menu-trigger__line menu-trigger__line--top">&nbsp;</span>
    <span className="menu-trigger__line menu-trigger__line--middle menu-trigger__line--close-1">
      &nbsp;
    </span>
    <span className="menu-trigger__line menu-trigger__line--middle menu-trigger__line--close-2">
      &nbsp;
    </span>
    <span className="menu-trigger__line menu-trigger__line--bottom">
      &nbsp;
    </span>
  </button>
)

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isMenuVisible: false,
      regex: "",
    }

    this.toggleMenu = this.toggleMenu.bind(this)
  }

  componentDidMount() {
    const script = document.createElement("script")
    script.src = withPrefix("/scripts/header.js")
    this.headerScript.appendChild(script)
    if (this.props.location.pathname.includes("/open-mic/")) {
      this.setState({ regex: new RegExp("[^\\/open\\-mic\\/](.*)") })
    }
  }

  toggleMenu() {
    this.setState(({ isMenuVisible }) => ({ isMenuVisible: !isMenuVisible }))
  }

  render() {
    const { location } = this.props
    const { isMenuVisible, regex } = this.state
    let isPostPage
    if (regex !== "") {
      isPostPage = regex.test(location.pathname)
    }

    return (
      <>
        <header
          className={`site-header site-header--initial site-header--fade ${
            isMenuVisible ? "menu--visible " : ""
          }${location.pathname !== "/" ? "subpage" : "home"}`}
        >
          <div className="site-header--left">
            <Link
              to="/"
              className={`header__btn header__logo ${
                location.pathname !== "/" ? "subpage" : "home"
              }`}
              title="Click to go home..."
            >
              <Logo />
            </Link>
          </div>

          <div className="site-header--right">
            <h2
              className={`page-title ${
                isPostPage ? "hide-post-name" : ""
              } ${location.pathname.includes("/open-mic") &&
                "header--open-mic"}`}
            >
              {getCurrentSectionTitle(location.pathname)}
            </h2>
            <MenuButton toggleMenu={this.toggleMenu} />
          </div>
        </header>
        <MainNavigation
          location={location}
          isMenuVisible={isMenuVisible}
          onTogglingMenu={this.toggleMenu}
        />
        <div ref={el => (this.headerScript = el)} />
      </>
    )
  }
}

export default Header
