import React from "react"
import PropTypes from "prop-types"
import { withPrefix } from "gatsby"

import SiteDiagnostics from "../homepage/siteDiagnostics"
import Header from "./siteHeader"
import Footer from "./siteFooter"

import "./siteLayout.scss"

class Layout extends React.Component {
  componentDidMount() {
    const ctmScript = document.createElement("script")
    ctmScript.src = withPrefix("/scripts/ctm.js")
    // const script = document.createElement("script")
    // script.src = withPrefix("/scripts/index.js")
    this.postPageStaticScript.appendChild(ctmScript)
    // this.postPageStaticScript.appendChild(script)
  }

  render() {
    const { children, location } = this.props
    return (
      <div className="site-wrapper">
        <SiteDiagnostics />
        <Header siteTitle="Amplified by Design" location={location} />

        <main className="site-content">{children}</main>

        <Footer />

        <div ref={el => (this.postPageStaticScript = el)} />
      </div>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
